import { all, takeEvery, put, call } from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchClassRosters, updateTubeId, uploadClassRosters } from 'services/classRosters';
import actions from './actions';

export function* callLoadClassRosters({ payload }) {
  try {
    const response = yield call(fetchClassRosters, payload);

    yield put({
      type: actions.FETCH_CLASS_ROSTERS_SUCCESS,
      payload: {
        data: response.data,
        firstPage: !response.data.previous,
      },
    });
  } catch (error) {
    const errorData = error.response.data;

    yield put({
      type: actions.FETCH_CLASS_ROSTERS_FAILURE,
      payload: {
        error: errorData?.detail,
      },
    });

    notification.error({
      message: 'Something went wrong',
      description: errorData?.detail,
    });

    return error;
  }
}

export function* callUpdateTubeId({ payload }) {
  try {
    const response = yield call(updateTubeId, payload);

    yield put({
      type: actions.UPDATE_TUBE_ID_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    const errorData = error.response.data;

    yield put({
      type: actions.UPDATE_TUBE_ID_FAILURE,
      payload: {
        error: errorData?.detail,
      },
    });

    notification.error({
      message: 'Something went wrong',
      description: errorData?.detail,
    });

    return error;
  }
}

export function* callUploadClassRosters({ payload }) {
  try {
    const response = yield call(uploadClassRosters, payload);

    yield put({
      type: actions.UPLOAD_CLASS_ROSTERS_SUCCESS,
      payload: {
        data: response.data,
      },
    });

    notification.success({
      message: 'File uploaded',
    });
  } catch (error) {
    yield put({
      type: actions.UPLOAD_CLASS_ROSTERS_FAILURE,
    });

    notification.error({
      message: error.message,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CLASS_ROSTERS_REQUEST, callLoadClassRosters),
    takeEvery(actions.UPDATE_TUBE_ID_REQUEST, callUpdateTubeId),
    takeEvery(actions.UPLOAD_CLASS_ROSTERS_REQUEST, callUploadClassRosters),
  ]);
}
