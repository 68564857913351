import labConfig from 'utils/labConfig';

console.log(process.env.REACT_APP_VERSION);
const REPORTING_MENU_ITEMS = [
  {
    title: 'Runs',
    key: 'runs',
    url: '/runs',
    icon: 'fe fe-box',
  },
  {
    title: 'Pools',
    key: 'pools',
    url: '/pools',
    icon: 'fe fe-folder',
  },
];

const REPORTING_URLS = ['/pools', '/runs'];

export async function getMenuData() {
  return [
    {
      title: 'Results',
      key: 'results',
      url: '/locations',
      icon: 'fe fe-compass',
    },
    {
      title: 'Users',
      key: 'users',
      url: '/users',
      icon: 'fe fe-user',
    },
    {
      title: 'Companies',
      key: 'companies',
      icon: 'fe fe-briefcase',
      url: '/companies',
    },
    ...(!labConfig[process.env.REACT_APP_LAB_ID]?.isLimsUser
      ? REPORTING_MENU_ITEMS
      : []),
    {
      title: 'Packing Slip',
      key: 'packingSlip',
      url: '/packing-slip',
      icon: 'fe fe-download',
    },
    {
      title: 'Barcode Lookup',
      key: 'search',
      icon: 'fe fe-search',
      url: '/barcode-lookup',
      isAlpha: true,
    },
    {
      title: 'Intake',
      key: 'intake',
      url: '/intake',
      icon: 'fe fe-monitor',
    },
    {
      title: 'Email Support',
      key: 'contactUs',
      icon: 'fe fe-mail',
    },
    {
      title: 'Help Center',
      key: 'helpCenter',
      icon: 'fa fa-question-circle-o',
    },
    {
      title: `v ${process.env.REACT_APP_VERSION}`,
      key: 'version',
      icon: 'fe fe-git-merge',
    },
  ];
}

export async function getRolePermissions() {
  return {
    admin: {
      permitted: [
        '/profile',
        '/users',
        '/companies',
        ...(!labConfig[process.env.REACT_APP_LAB_ID]?.isLimsUser
          ? REPORTING_URLS
          : []),
        '/packing-slip',
        '/barcode-lookup',
        '/intake',
      ],
      default: '/users',
    },
    staff: {
      permitted: [
        '/profile',
        '/users',
        '/companies',
        ...(!labConfig[process.env.REACT_APP_LAB_ID]?.isLimsUser
          ? REPORTING_URLS
          : []),
        '/packing-slip',
        '/barcode-lookup',
        '/intake',
      ],
      default: '/users',
    },
    'company-admin': {
      permitted: ['/profile', '/locations', '/packing-slip'],
      default: '/locations',
    },
  };
}
