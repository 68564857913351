import { constants } from 'utils/constants';
import actions from './actions';

const initialState = {
  items: [],
  locationId: null,
  isLoading: false,
  total: 0,
  offset: 0,
  error: null,
};

export default function classRostersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CLASS_ROSTERS_REQUEST: {
      return {
        ...state,
        locationId: action.payload.id,
        isLoading: true,
        error: null,
      };
    }
    case actions.FETCH_CLASS_ROSTERS_SUCCESS: {
      return {
        ...state,
        items: action.payload.firstPage
          ? action.payload.data.results
          : [...state.items, ...action.payload.data.results],
        total: action.payload.data.count,
        isLoading: false,
        offset: action.payload.firstPage
          ? constants?.classRosters?.itemsLoadingCount
          : state.offset + constants?.classRosters?.itemsLoadingCount,
      };
    }
    case actions.FETCH_CLASS_ROSTERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case actions.UPLOAD_CLASS_ROSTERS_REQUEST: {
      return {
        ...state,
        locationId: action.payload.id,
        isLoading: true,
        error: null,
      };
    }
    case actions.UPLOAD_CLASS_ROSTERS_SUCCESS: {
      return {
        ...state,
        items: [
          {
            title: action.payload.data.title,
            students: action.payload.data.students || [],
          },
          ...state.items,
        ],
        total: action.payload.data.count,
        isLoading: false,
        offset: action.payload.firstPage
          ? constants?.classRosters?.itemsLoadingCount
          : state.offset + constants?.classRosters?.itemsLoadingCount,
      };
    }
    case actions.UPLOAD_CLASS_ROSTERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
