const actions = {
  FETCH_CLASS_ROSTERS_REQUEST: 'classRosters/FETCH_CLASS_ROSTERS_REQUEST',
  FETCH_CLASS_ROSTERS_SUCCESS: 'classRosters/FETCH_CLASS_ROSTERS_SUCCESS',
  FETCH_CLASS_ROSTERS_FAILURE: 'classRosters/FETCH_CLASS_ROSTERS_FAILURE',
  UPDATE_TUBE_ID_REQUEST: 'classRosters/UPDATE_TUBE_ID_REQUEST',
  UPDATE_TUBE_ID_SUCCESS: 'classRosters/UPDATE_TUBE_ID_SUCCESS',
  UPDATE_TUBE_ID_FAILURE: 'classRosters/UPDATE_TUBE_ID_FAILURE',
  UPLOAD_CLASS_ROSTERS_REQUEST: 'classRosters/UPLOAD_CLASS_ROSTERS_REQUEST',
  UPLOAD_CLASS_ROSTERS_SUCCESS: 'classRosters/UPLOAD_CLASS_ROSTERS_SUCCESS',
  UPLOAD_CLASS_ROSTERS_FAILURE: 'classRosters/UPLOAD_CLASS_ROSTERS_FAILURE',
};

export default actions;
