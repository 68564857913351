import axiosClient from 'utils/axiosClient';
//TODO: change endpoint url
export const fetchClassRosters = async ({
  limit,
  offset,
  id,
}) => {
  return await axiosClient.get(`/company-locations/${id}/rosters/`, {
    params: {
      limit,
      offset,
    },
  });
};

export const updateTubeId = async ({
  id, tube_id, location_id
}) => {
  return await axiosClient.patch(`/company-locations/${location_id}/rosters/${id}/`, {
    tube_id
  });
};

export const uploadClassRosters = async (payload) => {
  const { file, onSuccess, onError } = payload?.options;
  try {
    const formData = new FormData();
    formData.append('roster_file', file);
    formData.append('title', file.name)

    const uploadedFile = await axiosClient.post(
      `/company-locations/${payload.id}/rosters/create`,
        formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
    onSuccess(uploadedFile);
    return uploadedFile;
  } catch (error) {
    onError(error);
    return error;
  }
};

